<template>
  <div class="rn_ms_section">
    <div class="sect_tit">
      <h5>
        <span>
          <img
            :src="reviewerImg ? reviewerImg : '@/assets/images/noimage.gif'"
            @error="replaceImg"
          /> </span
        >{{ reviewerNick }}님 리뷰
      </h5>
      <select name="" v-model="sort" @change="changeSort($event)">
        <option value="latest">최신순</option>
        <option value="trust_score">리뷰 신뢰도 순</option>
        <option value="comment">댓글수순</option>
      </select>
    </div>
    <div class="cate_wrap">
      <div class="cate_right">
        <!-- -->
        <ReviewGoodsCategory
          v-if="categories && categoryInfo"
          :category="categories"
          :id="!cateId ? categoryInfo[0].id : cateId"
          ref="goodsCategory"
          @getGoodsCategory="getGoodsCategory"
        />
        <!-- -->
      </div>
    </div>

    <!---->
    <div class="rn_ms_box_9">
      <!---->
      <div class="rn_ms_empty_20"></div>
      <!---->
      <!-- <div class="sect_g_sort">
        <div class="right">
          <div>
            <router-link
              to=""
              :class="{ on: sort === 'latest' }"
              @click.native="changeSort('latest')"
              >최신순</router-link
            >
            <router-link
              to=""
              :class="{ on: sort === 'trust_score' }"
              @click.native="changeSort('trust_score')"
            >
              리뷰 신뢰도순</router-link
            >
            <router-link
              to=""
              :class="{ on: sort === 'comment' }"
              @click.native="changeSort('comment')"
              >댓글수순</router-link
            >
          </div>
        </div>
      </div> -->

      <div class="sect_list" v-for="(item, index) in listArray" :key="item.seq">
        <ReviewGoodsListItem
          v-if="categoryName"
          :item="item"
          :index="parseInt(rank) !== 0 ? parseInt(rank) : index + 1"
          :nowPage="pageSnsUrl"
          :category="categoryName[0].id"
          :goods="true"
          :ref="`listItem_${item.seq}`"
          :id="`listItem_${item.seq}`"
          :paramsQuery="paramsQuery"
          @setSeq="setSeq"
        />
      </div>
      <div class="sect_list none_data" v-if="listArray.length <= 0">
        <p>검색된 리뷰가 없습니다.</p>
      </div>
      <div class="rn_more" v-if="totalPage > page">
        <router-link to="" @click.native="getReviewGoodsList()"
          >{{ limit }}개 더보기<img
            src="@/assets/images/sub/my_down_ico.png"
            alt=""
        /></router-link>
      </div>
      <div class="rn_more" v-if="totalPage <= page && parseInt(seq) !== 0">
        <router-link to="" @click.native="resetSeq"
          >목록으로<img src="@/assets/images/sub/my_down_ico.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewGoodsCategory from "@/components/review/state/ReviewGoodsCategory";
import ReviewGoodsListItem from "@/components/review/state/ReviewGoodsListItem";
export default {
  props: {
    from: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: 1,
      memberId: parseInt(this.$route.params.memberId) || null,
      cateId: parseInt(this.$route.params.cateId) || 0,
      category_code: this.$route.query.category || null,
      option1: parseInt(this.$route.query.option1) || 1,
      option2: parseInt(this.$route.query.option2) || 0,
      option3: parseInt(this.$route.query.option3) || 0,
      page: parseInt(this.$route.query.page) || 0,
      goodsSeq: parseInt(this.$route.query.goods_seq) || 0,
      sort: "latest",
      seq: this.$route.query.seq || 0,
      pageName: "/review/reviewer_goods",
      option1Backup: parseInt(this.$route.query.option1) || 1,
      providerId: parseInt(this.$route.params.providerId) || null,
      urlGoodsSeq: 0,
      searchText: null,
    };
  },

  mounted() {
    //초기화
    if (this.from === "myinfo") {
      this.$store.dispatch("common/setFooter", {
        mypage: true,
      });
    }

    this.getCategories();
    this.$emit("setMemberId", this.memberId);
  },
  components: {
    ReviewGoodsCategory,
    ReviewGoodsListItem,
  },
  computed: {
    ...mapState("review", [
      "mainMenuList",
      "result",
      "msg",
      "listArray",
      "totalPage",
      "limit",
      "categoryName",
      "rank",
      "reviewerNick",
      "reviewerImg",
    ]),
    ...mapState("shop", ["categories", "reviewGoodsMenu"]),
    ...mapState("mypage", ["myInfo"]),
    paramsQuery() {
      return `memberId=${this.memberId}&category_id=${this.cateId}&category_code=${this.category_code}&goods_seq=0&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}&page_name=reviewer`;
    },
    pageSnsUrl() {
      // console.log(
      //   `${process.env.VUE_APP_API_URL}${this.pageName}/${this.category_code}?goods_seq=${this.goodsSeq}&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`
      // );

      if (parseInt(this.option1) === 2 || parseInt(this.option1) === 3) {
        return `${process.env.VUE_APP_API_URL}/review/reviewer_goods/${this.memberId}/${this.cateId}?category_code=${this.category_code}&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`;
      }
      return `${process.env.VUE_APP_API_URL}/review/reviewer_goods/${this.memberId}/${this.cateId}?category_code=${this.category_code}&goods_seq=${this.urlGoodsSeq}&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`;
    },
    categoryInfo() {
      if (this.categories.length > 0) {
        if (this.category_code === null) {
          return [
            {
              index: 0,
              id: parseInt(this.categories[0].id),
              category_code: this.categories[0].category_code,
              title: this.categories[0].title,
              des: "카테고리코드가 없을떄",
            },
          ];
        }

        let index = this.categories.findIndex(
          (cate) => cate.category_code === this.category_code
        );

        if (index >= 0) {
          return [
            {
              index: index,
              id: parseInt(this.categories[0].id),
              category_code: this.categories[index].category_code,
              title: this.categories[index].title,
              des: "인덱스가 있을때",
            },
          ];
        }
      }
      return [
        {
          index: 0,
          id: null,
          category_code: process.env.VUE_APP_DEFAULT_GOOD, //상품의 기본 기초코드
          title: null,
          des: "널 일때 ",
        },
      ];
    },
    nowUrl() {
      return `${this.pageName}/${this.memberId}/${this.cateId}?category_code=${
        this.category_code
      }&option1=${this.option1}&option2=${this.option2}&option3=${
        this.option3
      }&seq=${!this.seq ? "0" : this.seq}`;
    },
  },
  methods: {
    goSearch(searchText) {
      if (searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }

      this.searchText = searchText;
      this.getReviewGoodsList(true);
    },
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    reSetView() {
      this.seq = 0;
      this.goodsSeq = 0;
      this.goPage();
      this.getReviewGoodsList(true);
    },
    resetSeq() {
      this.seq = 0;
      this.goodsSeq = 0;
      this.sort = "latest";
      this.resetDetail();
      this.getReviewGoodsList(true);
    },
    async getCategories() {
      // console.log(this.category_code, 2);
      await this.$store.dispatch("shop/getCategories");
      // console.log("this.cateId", this.cateId);
      if (!this.cateId) {
        this.$refs.goodsCategory.reflash(this.categoryInfo[0].index);
      } else {
        const cateIndex = this.categories.findIndex(
          (cate) => parseInt(cate.id) === parseInt(this.cateId)
        );
        // console.log(cateIndex);
        this.$refs.goodsCategory.reflash(cateIndex);
        this.category_code = this.categories[cateIndex].category_code;
      }
      this.category_code = !this.category_code
        ? this.categoryInfo[0].category_code
        : this.category_code;
      // console.log(this.category_code, 3);
      this.getReviewGoodsList(true);
    },
    async getReviewGoodsList(reset = false) {
      // console.log(this.category_code, 1);
      if (reset) {
        this.page = 0;
      }

      await this.$store.dispatch("review/getReviewGoodsList", {
        page_name: "reviewer",
        member_seq: this.memberId,
        option_category1: this.option1,
        option_category2: this.option2,
        option_category3: this.option3 == 0 ? "" : this.option3,
        category_code: this.category_code,
        sort: this.sort,
        page: this.page,
        main_menu_seq: this.menu,
        reset,
        sold_out_yn: this.soldOut ? "Y" : "N",
        seq: this.seq || 0,
        search_text: this.searchText,
        is_list: true,
        goods_seq:
          parseInt(this.goodsSeq) === 0 || !this.goodsSeq
            ? null
            : this.goodsSeq,
      });
      if (this.result) {
        if (parseInt(this.seq)) {
          if (this.$refs[`listItem_${this.seq}`][0]) {
            this.$refs[`listItem_${this.seq}`][0].openDetail();
          }
        }
      }
      if (!this.result) {
        this.$toast.default(this.msg);
        if (!this.reviewerNick) {
          this.$router.push("/");
        }
      }

      this.page++;
    },
    changeSort(event) {
      // console.log(sort);
      const sort = event.target.value;
      const box2 = document.querySelectorAll(".box_2.detail");
      [...box2].map((box) => (box.style.display = "none"));
      this.sort = sort;
      this.seq = 0;
      this.getReviewGoodsList(true);
    },
    goPage() {
      if (this.$route.fullPath === this.nowUrl) return false;
      this.$router.push(this.nowUrl).catch(() => {});
    },

    changOption(op1, op2 = 0, op3 = 0) {
      this.$emit("searchTextReset");
      this.searchText = null;
      this.option1 = parseInt(op1);
      this.option2 = parseInt(op2);
      this.option3 = parseInt(op3);
      this.seq = 0;
      this.goodsSeq = 0;
      this.sort = "latest";
      this.resetDetail();
      this.goPage();
      // console.log(2);
      this.getReviewGoodsList(true);
    },
    goCategory(event) {},
    setSeq(seq) {
      // this.seq = seq;
      if (parseInt(seq) > 0) {
        const goods = this.listArray.filter(
          (list) => parseInt(list.seq) === parseInt(seq)
        );
        this.urlGoodsSeq = goods[0].goods_seq;
        this.option1 = goods[0].option_category1;
      }

      const detail = document.querySelector(`#item_detail_${seq}`);
      const div = document.querySelector(`#listItem_${seq}`);

      if (detail) {
        div.scrollIntoView({ behavior: "smooth" });
      }
      // this.goPage();
    },
    optionsInitial() {
      // this.option1 = 1;
      this.option2 = 0;
      this.option3 = 0;
      this.sort = "latest";
      this.goodsSeq = 0;
    },
    getGoodsCategory(id) {
      console.log(id, this.cateId);

      if (parseInt(this.cateId) !== parseInt(id)) {
        this.cateId = parseInt(id);
        const category = this.categories.filter(
          (cate) => parseInt(cate.id) === parseInt(id)
        );
        this.category_code = category[0].category_code;

        this.optionsInitial();
        this.changOption(this.option1);
        // console.log(this.cateId, id, this.option1);
        this.goPage();

        // console.log(4);
        // console.log(this.categoryInfo, this.category_code, id);
      }
    },
    resetDetail() {
      const snsClass = document.querySelectorAll(".sns_box");
      const declPop = document.querySelectorAll(".declaration_pop");
      const replyList = document.querySelectorAll(".reply_list_box");
      const arrowReply = document.querySelectorAll(".arrow_reply");
      // const infoWord = document.querySelectorAll(".info_word");
      // const arrowInfo = document.querySelectorAll(".arrow_info");
      const detail = document.querySelectorAll(".detail");

      // [...infoWord].map((word) => {
      //   word.style.display = "none";
      // });
      // [...arrowInfo].map((info) => (info.textContent = "∨"));
      [...snsClass].map((sns) => {
        sns.style.display = "none";
      });
      [...declPop].map((decl) => {
        decl.style.display = "none";
      });
      [...replyList].map((reply) => {
        reply.style.display = "none";
      });
      [...arrowReply].map((reply) => (reply.textContent = "∨"));
      [...detail].map((d) => (d.style.display = "none"));
    },
  },
};
</script>

<style lang="scss" scoped>
.sect_tit {
  margin: 20px 0 10px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  padding: 0 5px;
  box-sizing: border-box;
  h5 {
    font-size: 14px;
    line-height: 20px;
  }
  select {
    position: absolute;
    top: 0;
    right: 5px;
    height: 20px;
    border: 0 none;
    color: #757575;
  }
}
.provider_name {
  font-weight: 600;
  font-size: 13px;
  p {
    padding: 5px 5px;
  }
}
.right {
  width: auto;
  float: right !important;
}
.cate_right {
  width: auto;
  float: none;
}
.sect_tit {
  margin: 20px 0 10px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  padding: 0 5px;
  box-sizing: border-box;
  h5 {
    font-size: 14px;
    line-height: 30px;
  }
  h5 img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 15px;
    overflow: hidden;
  }
  select {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    border: 0 none;
    color: #757575;
  }
}
.sect_list {
  &.none_data {
    text-align: center;
    padding: 10px 0px 0px 0px;
  }
}
</style>
